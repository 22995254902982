import React from "react";
import Image from "next/image";
import marketplace from "./assets/images/cover.jpg";
import { SectionTitle } from "./common/SectionTitle";
import pattern1 from "./assets/images/pattern1.png";
import { DialogContext, DialogName } from "../context/DialogContext";
// @ts-ignore
import Pulse from "react-reveal/Pulse";

export default function MarketplaceSection() {
  const { setCurrentDialog } = React.useContext(DialogContext);
  return (
    <section className="relative h-auto overflow-hidden">
      <div className="absolute hidden -right-48 md:block">
        <Image src={pattern1} alt="Maxima Blockchain" />
      </div>
      <div className="px-4 py-10 mx-auto mb-36 max-w-7xl sm:px-6 lg:px-8">
        <SectionTitle subText="Token-Based Syndication" isSubTextAbove>
          Maxima Racehorse Marketplace
        </SectionTitle>
        <Pulse>
          <div className="overflow-hidden bg-gray-900 shadow-xl rounded-3xl lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="px-6 pt-10 pb-12 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-2xl font-extrabold text-white md:text-4xl">
                  <span className="block leading-10 text-center lg:text-left">
                    To be one of the first
                  </span>
                </h2>
                <p className="mt-4 text-sm antialiased leading-7 text-center text-indigo-200 md:text-left lg:text-lg">
                  Maxima will facilitate the crossover from “asset to
                  experience”, overseeing everything from caring for and
                  training the horses to arranging experiential days for
                  token-holders; the management of which is also executed using
                  smart contracts on the blockchain.
                </p>
                <button
                  onClick={() => setCurrentDialog(DialogName.Contact)}
                  className="inline-flex items-center px-5 py-3 mt-8 text-base font-medium border rounded-md shadow border-maxima-secondary-400 bg-transparant text-maxima-secondary-400 hover:border-green-400 hover:text-green-400"
                >
                  Explore Marketplace
                </button>
              </div>
            </div>
            <div className="flex -mt-6">
              <Image
                src={marketplace}
                alt="marketplace"
                objectFit="cover"
                placeholder="blur"
              />
            </div>
          </div>
        </Pulse>
      </div>
    </section>
  );
}
