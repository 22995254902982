import React from "react";
import Image from "next/image";
import FeatureOne from "./assets/images/campaign.png";
import Kycmint from "./assets/images/kycmint.jpg";
import Listracehorse from "./assets/images/listracehorse.jpg";
import Buynow from "./assets/images/buynow.jpg";
import Money from "./assets/images/money.jpg";
import Crown from "./assets/images/crown.jpg";
import Prize from "./assets/images/prize.jpg";
import { SectionTitle } from "./common/SectionTitle";
// @ts-ignore
import Slide from "react-reveal/Slide";
import pattern1 from "./assets/images/pattern1.png";
// @ts-ignore
import Pulse from "react-reveal/Pulse";
// @ts-ignore
import Swing from "react-reveal/Swing";
import { DialogContext } from "../context/DialogContext";
import MarketplaceSection from "../components/Marketplace";

const transferFeatures = [
  {
    id: 1,
    name: "No more complicated process",
    description:
      "No middlemen with slow efficiency involved in transactions, complicated transfer processes are simplified.",
  },
  {
    id: 2,
    name: "Great price transparency without hidden fees",
    description:
      "Every sale of an item is recorded on the blockchain and the data is publicly accessible, it greatly increases price transparency. This could benefit investors and collectors.",
  },
  {
    id: 3,
    name: "Liquidity & Accessibility",
    description:
      "With shares transfer made available through blockchain, we are able to construct diversified racehorse portfolios for owners and client exposure for service providers.",
  },
];

const incentives = [
  {
    name: "Creator KYC + Mint",
    description:
      "A racehorse NFT is minted once the horse is verified and the creator passes the KYC",
    imageSrc: Kycmint,
  },
  {
    name: "Syndication / Auction Listing",
    description:
      "A racehorse is either fully owned by a user or shared by multiple users in fractional shares",
    imageSrc: Listracehorse,
  },
  {
    name: "Purchase or Bid",
    description: "A user buys or bids on a unique racehorse for a Genesis-NFT",
    imageSrc: Buynow,
  },
];

const investments = [
  {
    name: "Appreciation",
    description:
      "Racehorses price escalate when they win races, giving themselves higher asset value",
    imageSrc: Money,
  },
  {
    name: "Royalties / Staking",
    description:
      "Creators receive royalties from every transaction onwards. Users enjoy interests from staking $MAX",
    imageSrc: Crown,
  },
  {
    name: "Tournament Bonus",
    description:
      "The bonus with extra $MAX to owners whose racehorse performs well in the tournament rank",
    imageSrc: Prize,
  },
];

export default function Features() {
  return (
    <section className="overflow-hidden anchor-features">
      <div className="relative px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="absolute hidden -top-72 -right-1/2 md:block">
          <Image src={pattern1} alt="Maxima Blockchain" />
        </div>
        <div className="relative">
          <SectionTitle subText="The revolutionary features" isSubTextAbove>
            The Next Generation of Ownership
          </SectionTitle>
        </div>

        <div className="mt-6 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-12">
          <Pulse>
            <div className="relative text-center lg:text-left">
              <h3 className="text-2xl font-extrabold tracking-tight text-gray-300 md:text-4xl">
                Buy and sell racehorses at ease
              </h3>
              <p className="mt-3 text-lg antialiased leading-7 text-gray-300 md:text-base">
                The ERC-721 & ERC-1155 introduce a standard for NFT, in other
                words, this type of Token is unique and can have different value
                than another Token from the same Smart Contract. The syndicate
                will be split into token-based shares to be traded on the Maxima
                platform, where share-owners can gain access to a transparent
                account of a horse’s history.
              </p>

              <dl className="mt-10 space-y-10">
                {transferFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <p className="text-xl font-medium leading-6 text-maxima-secondary-200 md:text-2xl">
                      {item.name}
                    </p>

                    <dd className="mt-2 text-lg antialiased text-gray-400 md:text-base">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </Pulse>

          <div className="w-full mx-auto mt-12 lg:mx-0 md:mt-2 xl:w-8/12 justify-self-end">
            <Swing>
              <Image
                src={FeatureOne}
                alt="Maxima Racehorse"
                placeholder="blur"
              />
            </Swing>
          </div>
        </div>

        <div className="absolute hidden -left-1/2 bottom-32 md:block">
          <Image src={pattern1} alt="Maxima Blockchain" />
        </div>

        <div className="py-12 mx-auto max-w-7xl sm:px-2 sm:py-24 lg:px-4">
          <SectionTitle isCentered isSubTextAbove>
            How It Works
          </SectionTitle>
          <div className="grid max-w-2xl grid-cols-1 px-4 mx-auto gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-3">
            {incentives.map((incentive, index) => (
              <Slide
                key={incentive.name}
                left={index === 0}
                bottom={index === 1}
                right={index === 2}
              >
                <div className="text-center sm:flex sm:text-left lg:block lg:text-center">
                  <div className="flex-shrink-0">
                    <div className="flow-root">
                      <Image
                        className={`mx-auto ${index === 0 ? "-rotate-3" : ""} ${
                          index === 2 ? "rotate-2" : ""
                        }`}
                        src={incentive.imageSrc.src}
                        alt="features"
                        objectFit="contain"
                        height={280}
                        width={400}
                      />
                    </div>
                  </div>
                  <div className="content-center mt-3 sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0 sm:flex sm:flex-wrap lg:block">
                    <h3 className="text-lg font-bold text-center text-gray-300 md:text-2xl">
                      {incentive.name}
                    </h3>
                    <p className="mt-6 text-lg text-center text-gray-400">
                      {incentive.description}
                    </p>
                  </div>
                </div>
              </Slide>
            ))}
          </div>
        </div>
      </div>

      <div className="py-10 mx-auto max-w-7xl sm:px-2 sm:py-20 lg:px-4">
        <SectionTitle subText="More than a NFT" isSubTextAbove>
          Own. Race. Earn. Repeat.
        </SectionTitle>
        <Pulse>
          <div className="grid max-w-2xl grid-cols-1 px-4 mx-auto gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-3">
            {investments.map((investment) => (
              <div
                key={investment.name}
                className="text-center sm:flex sm:text-left lg:block lg:text-center"
              >
                <div className="flex-shrink-0">
                  <div className="flow-root">
                    <Image
                      className="mx-auto"
                      src={investment.imageSrc.src}
                      alt="features"
                      objectFit="contain"
                      height={182}
                      width={260}
                    />
                  </div>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0 sm:flex sm:flex-wrap sm:content-center lg:block">
                  <h3 className="text-2xl font-bold text-center text-gray-300 md:text-3xl">
                    {investment.name}
                  </h3>
                  <p className="mt-6 text-lg text-gray-400 md:text-xl">
                    {investment.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Pulse>
      </div>
      <MarketplaceSection />
    </section>
  );
}
